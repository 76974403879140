import appConfig from '../../config/app';
import { getAuthHeaders } from '../fetchers/auth';

export const createNote = async (uid, text, authorId) => {
  const options = await getAuthHeaders();
  const response = await fetch(
    `${appConfig.baseApiUrl}/api/v1/users/${uid}/notes`,
    {
      method: 'POST',
      headers: {
        ...options,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        note: {
          text: text,
          author_id: authorId,
        },
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to create note');
  }

  const newNote = await response.json();
  return newNote;
};

// For future use when implementing note deletion
export const deleteNote = async (uid, noteId) => {
  const response = await fetch(
    `${appConfig.baseApiUrl}/api/v1/users/${uid}/notes/${noteId}`,
    {
      method: 'DELETE',
    }
  );

  if (!response.ok) {
    throw new Error('Failed to delete note');
  }

  return true;
};

// For future use when implementing note fetching
export const getUserNotes = async (uid) => {
  const response = await fetch(
    `${appConfig.baseApiUrl}/api/v1/users/${uid}/notes`,
    {
      headers: await getAuthHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch notes');
  }

  const notes = await response.json();
  return notes;
};
