import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { createGlobalStyle } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import firebase from 'firebase';
import { navigate } from '@reach/router';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BannedUser from '@material-ui/icons/Block';
import get from 'lodash.get';
import Menu from '@material-ui/icons/Menu';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PrivacyIcon from '@material-ui/icons/Lock';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Family from '@material-ui/icons/People';
import Members from '@material-ui/icons/Group';
import DashBoard from '@material-ui/icons/Dashboard';
import { Link } from '@reach/router';
import { useUser } from '../lib/hooks/use-user';
import { AppContext } from '../app';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => (props.whiteColor ? 'white' : '#303030')};
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;    
    };
    .wrapper-test {
        width: 100%;
        .firebaseui-list-item {
            button {
               width: 100%;
               max-width: 100%;
               height: 70px;
            }
        }
    }
}
`;

const PageWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  color: 'white';
`;

const PageContent = styled.div`
  @media (max-width: 600px) {
    margin: ${(props) => (props.noMobileMargin ? '16px 0' : '16px auto')};
    width: ${(props) => (props.noMobileMargin ? '100%' : '90%')};
  }
  margin: 16px auto;
  width: 90%;
  max-width: ${(props) => (props.dt ? '1200px' : '700px')};
`;

const AppBar = styled(Flex)`
  width: 100%;
  height: 50px;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 16px;
  background: #03a9f4;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
`;

const SideMenuContent = styled.div`
  height: 100vh;
  width: 300px;
`;

const SubHeaderContent = styled.div`
  display: flex;
  padding: 16px 0px;
`;

const BottomContent = styled.div`
  padding: 16px;
`;

const NameContainer = styled.div`
  width: 100%;
  margin-left: 16px;
`;

const styles = {
  header: {
    background: '#303030',
  },
  button: {
    width: '100%',
  },
  logout: {
    width: '100%',
  },
  name: {
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const PageLayout = (props) => {
  const {
    leftButton,
    rightButton = () => null,
    bottomNav = () => null,
    pageTitle,
    logoTitle,
    children,
    classes,
    selected,
    dt,
    noMobileMargin = false,
  } = props;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [get(location, 'pathname')]);

  const { loggedInUserId } = useContext(AppContext);
  const { user } = useUser(loggedInUserId);

  const [drawer, setDrawer] = useState(false);

  const hamburgerMenu = () => {
    return <Menu onTouchStart={toggleMenu} onClick={toggleMenu} />;
  };

  const toggleMenu = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('buttonPushed');
    }
    setDrawer((prevState) => !prevState);
  };

  const logOut = async () => {
    try {
      await firebase.auth().signOut();
      Cookies.remove('authToken');
      navigate('/login');
    } catch (e) {
      console.log('oops something went wrong');
    }
  };

  const permissions = get(user, 'permissions', []);
  const isAdmin = permissions.includes('admin');
  const isKiosk = permissions.includes('kiosk');
  const gymUUID = get(user, 'gym.uuid');

  return (
    <PageWrapper>
      <SwipeableDrawer open={drawer} onOpen={toggleMenu} onClose={toggleMenu}>
        <SideMenuContent>
          <List
            component="nav"
            subheader={
              <ListSubheader className={get(classes, 'header')}>
                <SubHeaderContent>
                  <Avatar src={get(user, 'avatar_url')}>TD</Avatar>
                  <NameContainer>
                    <Typography>{get(user, 'name')}</Typography>
                    <Typography className={classes.name}>
                      {get(user, 'email')}
                    </Typography>
                  </NameContainer>
                </SubHeaderContent>
              </ListSubheader>
            }
          >
            <Link to={`/profile/${get(user, 'uid')}`}>
              <ListItem button selected={selected === 'profile'}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </Link>
            <Link to={`/profile/${get(user, 'uid')}/children`}>
              <ListItem button selected={selected === 'children'}>
                <ListItemIcon>
                  <Family />
                </ListItemIcon>
                <ListItemText primary="Family" />
              </ListItem>
            </Link>
            {isAdmin && (
              <Link to={`/admin/dashboard/${gymUUID}`}>
                <ListItem button selected={selected === 'dashboard'}>
                  <ListItemIcon>
                    <DashBoard />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </Link>
            )}
            {isAdmin && (
              <Link to={`/admin/gyms/${gymUUID}/users`}>
                <ListItem button selected={selected === 'members'}>
                  <ListItemIcon>
                    <Members />
                  </ListItemIcon>
                  <ListItemText primary="Gym Members" />
                </ListItem>
              </Link>
            )}
            {(isAdmin || isKiosk) && (
              <Link to={`/admin/gyms/${gymUUID}/kiosk`}>
                <ListItem button selected={selected === 'kiosk'}>
                  <ListItemIcon>
                    <CheckCircle />
                  </ListItemIcon>
                  <ListItemText primary="Kiosk Checkin" />
                </ListItem>
              </Link>
            )}
            {isAdmin && (
              <Link to={`/admin/gyms/${gymUUID}/disabled-users`}>
                <ListItem button selected={selected === 'disabled'}>
                  <ListItemIcon>
                    <BannedUser />
                  </ListItemIcon>
                  <ListItemText primary="Disabled Accounts" />
                </ListItem>
              </Link>
            )}
            {isAdmin && (
              <Link to={`/admin/gyms/${gymUUID}/promotions`}>
                <ListItem button selected={selected === 'promotions'}>
                  <ListItemIcon>
                    <CheckCircle />
                  </ListItemIcon>
                  <ListItemText primary="Promotions" />
                </ListItem>
              </Link>
            )}
            <Link to={`/privacy`}>
              <ListItem button selected={selected === 'privacy'}>
                <ListItemIcon>
                  <PrivacyIcon />
                </ListItemIcon>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
            </Link>
          </List>
        </SideMenuContent>
        <BottomContent>
          <Button
            onClick={logOut}
            className={get(classes, 'logout')}
            variant="outlined"
          >
            Logout
          </Button>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <small>v 4.6.0</small>
          </div>
        </BottomContent>
      </SwipeableDrawer>
      <GlobalStyle />
      <AppBar>
        <Box width={1 / 4}>{leftButton ? leftButton : hamburgerMenu()}</Box>
        <Box width={1 / 2}>
          <Typography variant="h6" color="inherit" align="center">
            {pageTitle ? pageTitle : <Avatar src={logoTitle} />}
          </Typography>
        </Box>
        <Flex justifyContent="flex-end" width={1 / 4}>
          {rightButton}
        </Flex>
      </AppBar>
      <PageContent noMobileMargin={noMobileMargin} dt={dt}>
        {children}
      </PageContent>
      {bottomNav()}
    </PageWrapper>
  );
};

export default withStyles(styles)(PageLayout);
