import React, { useState, useContext } from 'react';
import { useUserStats } from '../../../lib/hooks/use-user';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { Chip, Divider, Button } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import ArrowDrowdown from '@material-ui/icons/ArrowDropDown';
import { Typography } from '@material-ui/core';
import { Flex, Box } from '@rebass/grid';
import UserAvatar from '../../../components/atom/user-avatar';
import get from 'lodash.get';
import { format } from 'date-fns';
import { CheckinChartCard } from '../components/checkin-chart';
import { TrendsChart } from './trends-chart';
import StatCard from '../../../components/stat-card';
import MobileSelector from '../../organisim/mobile-selector';
import OptionLink from '../../atom/options-link';
import { useDrawer } from '../../../lib/hooks/use-drawer';
import { LineChart } from './line-chart';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import UserListItem from '../../organisim/user-list-item';
import { AppContext } from '../../../app';
import { disableUser } from '../../../lib/mutations/disable-user';
import { useUser } from '../../../lib/hooks/use-user';
import { useMutation } from 'react-query';
import AddCheckinsPanel from './add-checkin-panel';
import Modal from '@material-ui/core/Modal';
import { useModal } from '../../../lib/hooks/use-modal';
import { DateObject } from 'react-multi-date-picker';
import { useUserCheckins } from '../../../lib/hooks/use-user-checkins';
import { logBulkCheckins } from '../../../lib/mutations/checkin';
import { useQueryClient } from 'react-query';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Notes';
import CloseIcon from '@material-ui/icons/Close';
import { createNote } from '../../../lib/mutations/notes';
import { useUserNotes } from '../../../lib/hooks/use-user-notes';

const ProfileContainer = styled.div`
  padding: 20px 20px;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  grid-gap: 20px;
  grid-template-areas:
    'profile profile profile'
    'profile-notes profile-notes profile-notes'
    'child-accounts child-accounts child-accounts'
    'stat-card stat-card stat-card'
    'this-week 6-week 6-month';

  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-areas:
      'profile profile'
      'profile-notes profile-notes'
      'child-accounts child-accounts'
      'stat-card stat-card'
      'this-week 6-week'
      '6-month  6-month';
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-areas:
      'profile'
      'profile-notes'
      'child-accounts'
      'stat-card'
      'combined-chart';
    grid-template-columns: 1fr;
  }
`;

const ChartControls = styled.div`
  font-size: 12px;
  color: ${({ selected }) => (selected ? '#fff' : '#a6a4a4')};
  font-weight: 600;
`;

const NotesPreviewContainer = styled.div`
  position: relative;
`;

const FadedListItem = styled(ListItem)`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(transparent 20%, #424242);
    pointer-events: none;
  }
`;

const ScrollableList = styled(List)`
  position: relative;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }
`;

const convertDateToYears = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12;
  ageInMonths -= birthDate.getMonth();
  ageInMonths += today.getMonth();
  const age = ageInMonths / 12;
  return age.toFixed(2); // returns age with two decimal places
};

export const Profile = ({ user, classes }) => {
  const { stats = {} } = useUserStats(user.uid);
  const [statCardData, setStatCardData] = useState('active_days');
  const [activeChart, setActiveChart] = useState('Six week');
  const { loggedInUserId } = useContext(AppContext);
  const { user: currentUser } = useUser(loggedInUserId);
  const [userDisabled, setUserDisabled] = useState(false);
  const { isOpen, openModal, closeModal } = useModal();
  const gym = currentUser.gym;

  const { checkins } = useUserCheckins(user.id);
  const checkinDates = checkins.map((checkin) => checkin.created_at);
  const [newCheckins, setNewCheckins] = useState([]);

  const queryClient = useQueryClient();

  const { mutate: logCheckins, isLoading: loadingCheckins } = useMutation(
    (checkins) => logBulkCheckins(checkins, gym?.id, user?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user', user.uid]);
        queryClient.invalidateQueries(['user-stats', user.uid]);
        queryClient.invalidateQueries('gym-users');

        setNewCheckins([]);
        closeModal();
      },
    }
  );

  const handleNewCheckins = (dateObjects) => {
    const newDates = dateObjects.map((date) => date.format('MM/DD/YYYY'));
    const oldCheckins = checkinDates.map((date) =>
      new DateObject(date).format('MM/DD/YYYY')
    );
    const newCheckins = newDates.filter((date) => !oldCheckins.includes(date));
    setNewCheckins(newCheckins);
  };

  const handleRemoveCheckin = (date) => {
    const newDates = newCheckins.filter((checkinDate) => checkinDate !== date);
    setNewCheckins(newDates);
  };

  const disableExistingCheckins = (date) => {
    const dateObject = new DateObject(date);
    const dateStr = dateObject.format('YYYY/MM/DD');

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = new DateObject(yesterday).format('YYYY/MM/DD');

    const isCheckinDate = checkinDates.some(
      (checkinDate) =>
        new DateObject(checkinDate).format('YYYY/MM/DD') === dateStr
    );

    if (isCheckinDate) {
      return {
        ...dateObject,
        className: 'checkin-date',
      };
    } else if (dateStr > yesterdayStr) {
      return {
        ...dateObject,
        disabled: true,
      };
    }
    return dateObject;
  };

  const handleCloseCheckinModal = (checkins) => {
    setNewCheckins([]);
    closeModal();
  };

  const convertDateToIso = (dateStr) => {
    if (Date.parse(dateStr)) {
      const date = new Date(dateStr);
      date.setUTCHours(12, 0, 0, 0);
      const isoStr = date.toISOString();
      return isoStr;
    } else {
      throw new Error(`Invalid date string: ${dateStr}`);
    }
  };

  const handleSaveCheckins = (checkins, userId, gymId) => {
    const convertedCheckins = newCheckins.map((date) => convertDateToIso(date));

    logCheckins(convertedCheckins);
  };

  const { mutate, isLoading } = useMutation(
    (requestBody, adminUserId) => disableUser(requestBody, adminUserId),
    {
      onSuccess: (data) => {
        setUserDisabled(true);
      },
    }
  );

  const {
    toggleDrawer: toggleChartDrawer,
    isDrawerOpen: isChartDrawerOpen,
  } = useDrawer();
  const { isMobile } = useIsMobile();
  const {
    isDrawerOpen: isDataTypeDrawerOpen,
    toggleDrawer: toggleDataTypeDrawer,
  } = useDrawer();
  const formatCheckIn = (checkins) => {
    if (!checkins)
      return [
        {
          x: 'loading data...',
          y: 0,
        },
      ];
    return Object.entries(checkins).map(([day, count]) => ({
      x: day,
      y: count,
    }));
  };

  const drawerClick = () => {
    toggleDataTypeDrawer();
  };

  const handleDisableClick = async () => {
    const disableBody = {
      disable_user: {
        user_id: user.id,
        reason:
          'This account has been marked as a duplicate, if you believe this is an error please contact the gym admin.',
        disabled_by_id: currentUser.id,
      },
    };

    await mutate(disableBody, loggedInUserId);
  };

  const statCountDataSet = {
    thisWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_weekly_checkins'
        : 'meta.total_weekly_active_days'
    ),
    sixWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_six_weeks_checkins'
        : 'meta.total_six_weeks_active_days'
    ),
    sixMonth: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_six_months_checkins'
        : 'meta.total_six_months_active_days'
    ),
    lastPromotionCount: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.checkins_count_since_last_promotion'
        : 'meta.active_days_since_last_promotion'
    ),
  };

  const statDataSet = {
    thisWeek: get(
      stats,
      statCardData === 'checkins' ? 'this_week_checkins' : 'this_week_checkins'
    ),
    sixWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'last_six_weeks_data'
        : 'last_six_weeks_active_days'
    ),
    sixMonth: get(
      stats,
      statCardData === 'checkins'
        ? 'last_six_months_data'
        : 'last_six_months_active_days'
    ),
  };

  const activeChartData = () => {
    switch (activeChart) {
      case 'This week':
        return statDataSet.thisWeek;
      case 'Six week':
        return statDataSet.sixWeek;
      case 'Six month':
        return statDataSet.sixMonth;
      default:
        return statDataSet.sixWeek;
    }
  };

  const hasChildren = user?.children?.length > 0;
  const hasParent = user?.parent && user?.parent.id;

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const { notes, isLoading: isLoadingNotes } = useUserNotes(user.uid);
  const [newNote, setNewNote] = useState('');

  const { mutate: addNote, isLoading: isAddingNote } = useMutation(
    ({ userId, text, authorId }) => createNote(userId, text, authorId),
    {
      onSuccess: (newNote) => {
        queryClient.invalidateQueries(['user-notes', user.uid]);
        setNewNote('');
      },
      onError: (error) => {
        console.error('Failed to add note:', error);
      },
    }
  );

  const handleAddNote = () => {
    if (!newNote.trim()) return;

    addNote({
      userId: user.uid,
      text: newNote,
      authorId: currentUser.uid,
    });
  };

  const [showModalShadow, setShowModalShadow] = useState(false);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    setShowModalShadow(!bottom);
  };

  return (
    <ProfileContainer>
      <Card style={{ gridArea: 'profile' }}>
        <CardContent>
          <Flex alignItems="center">
            <Box mb="8px">
              <UserAvatar
                src={get(user, 'avatar_url')}
                name={get(user, 'name', '')}
                size="small"
              />
            </Box>
            <Box ml="8px">
              <Typography variant="h5">{get(user, 'name', '')}</Typography>
              <Typography variant="body1">{get(user, 'email', '')}</Typography>
            </Box>
          </Flex>
          <Divider />
          <Flex mt="8px" justifyContent="space-between">
            <Box>
              <Typography>
                Gym Years:{' '}
                {convertDateToYears(
                  get(user, 'start_date') || get(user, 'startDate')
                )}
              </Typography>
              <Typography>
                Jits Years: {convertDateToYears(get(user, 'jits_start_date'))}
              </Typography>
              <Typography>
                Age: {convertDateToYears(get(user, 'dob', ''))}
              </Typography>
              <Typography>Rank: {get(user, 'belt.name')}</Typography>
              <Typography>
                Last Promotion:{' '}
                {stats && stats.last_promotion
                  ? format(stats?.last_promotion?.promoted_date, 'MM-DD-YYYY')
                  : 'No promotions'}
              </Typography>
              {get(user, 'roles', []).length > 0 && (
                <Flex>
                  <Typography>Roles:</Typography>
                  <Box ml="8px">
                    {get(user, 'permissions', []).map((permission) => (
                      <Chip label={permission.name} key={permission} />
                    ))}
                  </Box>
                </Flex>
              )}
            </Box>
            {!hasParent && (
              <Box>
                <Button
                  variant="outlined"
                  style={{ color: '#d43737', borderColor: '#d43737' }}
                  onClick={handleDisableClick}
                  disabled={isLoading || userDisabled}
                >
                  {userDisabled ? 'Disabled' : 'Disable'}
                </Button>
              </Box>
            )}
          </Flex>
        </CardContent>
      </Card>

      <Card style={{ gridArea: 'profile-notes', marginTop: '20px' }}>
        <CardContent>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Recent Notes</Typography>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setIsNotesModalOpen(true)}
              startIcon={<NotesIcon />}
            >
              Add New Note
            </Button>
          </Flex>
          <NotesPreviewContainer>
            {isLoadingNotes ? (
              <Typography>Loading notes...</Typography>
            ) : notes.length === 0 ? (
              <Box py={2}>
                <Typography color="textSecondary" align="center">
                  No notes yet
                </Typography>
              </Box>
            ) : (
              <>
                <List>
                  {notes.slice(0, 2).map((note, index) => (
                    <React.Fragment key={note.id}>
                      {index === 1 && notes.length > 2 ? (
                        <FadedListItem>
                          <ListItemText
                            primary={note.text}
                            secondary={`${note.author} - ${format(
                              new Date(note.created_at),
                              'MM/DD/YYYY hh:mm a'
                            )}`}
                          />
                        </FadedListItem>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={note.text}
                            secondary={`${note.author} - ${format(
                              new Date(note.created_at),
                              'MM/DD/YYYY hh:mm a'
                            )}`}
                          />
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
                {notes.length > 2 && (
                  <Box
                    onClick={() => setIsNotesModalOpen(true)}
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      color: '#90caf9',
                      marginTop: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '4px',
                    }}
                  >
                    <Typography variant="body2">
                      See {notes.length - 2} more notes
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </NotesPreviewContainer>
        </CardContent>
      </Card>

      <Modal open={isNotesModalOpen} onClose={() => setIsNotesModalOpen(false)}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#424242',
            padding: '20px',
            borderRadius: '8px',
            outline: 'none',
            width: '90%',
            maxWidth: '600px',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">User Notes</Typography>
            <IconButton
              onClick={() => setIsNotesModalOpen(false)}
              style={{ padding: '8px' }}
            >
              <CloseIcon />
            </IconButton>
          </Flex>
          <Divider />

          <Box my={2} mt={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Add a new note..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              disabled={isAddingNote}
            />
            <Flex justifyContent="flex-end" mt={3} mb={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNote}
                disabled={!newNote.trim() || isAddingNote}
              >
                {isAddingNote ? 'Adding...' : 'Add Note'}
              </Button>
            </Flex>
          </Box>

          <Divider />

          <ScrollableList showShadow={showModalShadow} onScroll={handleScroll}>
            {isLoadingNotes ? (
              <Typography>Loading notes...</Typography>
            ) : notes.length === 0 ? (
              <Box
                py={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography color="textSecondary" align="center">
                  No notes have been added yet. Add a note above to get started.
                </Typography>
              </Box>
            ) : (
              notes.map((note) => (
                <ListItem key={note.id}>
                  <ListItemText
                    primary={note.text}
                    secondary={`${note.author} - ${format(
                      new Date(note.created_at),
                      'MM/DD/YYYY hh:mm a'
                    )}`}
                  />
                </ListItem>
              ))
            )}
          </ScrollableList>
        </Box>
      </Modal>

      {!hasChildren && !hasParent && (
        <Card style={{ gridArea: 'child-accounts' }}>
          <CardContent>
            <Box mb="16px">
              <Typography variant="h6">No family accounts</Typography>
            </Box>
          </CardContent>
        </Card>
      )}
      {hasParent && (
        <Card style={{ gridArea: 'child-accounts' }}>
          <CardContent>
            <Box mb="16px">
              <Typography variant="h6">Parent Account</Typography>
            </Box>
            <Divider />
            <UserListItem
              style={{
                height: '40px',
                img: {
                  width: '30px',
                  height: '30px',
                },
              }}
              key={user.parent.id}
              user={user.parent}
            />
            <Divider />
          </CardContent>
        </Card>
      )}
      {hasChildren && (
        <Card style={{ gridArea: 'child-accounts' }}>
          <CardContent>
            <Box mb="16px">
              <Typography variant="h6">Child Accounts</Typography>
            </Box>
            {user.children.map((child) => (
              <>
                <UserListItem
                  key={child.id}
                  user={child}
                  style={{
                    height: '40px',
                    img: {
                      width: '30px',
                      height: '30px',
                    },
                  }}
                />
                <Divider />
              </>
            ))}
          </CardContent>
        </Card>
      )}
      <Box style={{ gridArea: 'stat-card' }}>
        <Flex justifyContent="space-between">
          <Flex mb="16px" alignItems="center" onClick={drawerClick}>
            <Typography variant="h6" style={{ cursor: 'pointer' }}>
              {statCardData === 'checkins' ? 'Check Ins' : 'Active Days'}
            </Typography>
            <Box ml="4px">
              <ArrowDrowdown style={{ fontSize: '30px' }} />
            </Box>
          </Flex>
          <Box>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={openModal}
            >
              Add Checkins
            </Button>
          </Box>
        </Flex>
        <StatCard
          weekCount={statCountDataSet.thisWeek}
          sixWeekCount={statCountDataSet.sixWeek}
          monthCount={statCountDataSet.sixMonth}
          lastPromotionCount={statCountDataSet.lastPromotionCount}
        />
        <Modal open={isOpen} onClose={closeModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#424242',
              padding: '20px',
              borderRadius: '8px',
              outline: 'none',
            }}
          >
            <Flex mb="16px">
              <Typography variant="h6">
                Add Checkins for {user.name}{' '}
              </Typography>
            </Flex>
            <Divider />
            <AddCheckinsPanel
              existingCheckins={checkinDates}
              handleNewCheckins={handleNewCheckins}
              newCheckins={newCheckins}
              disableCalendarDays={disableExistingCheckins}
              handleRemoveCheckin={handleRemoveCheckin}
              isLoading={loadingCheckins}
            />
            <Box mb="16px">
              <Divider />
            </Box>
            <Flex justifyContent="space-between">
              <Box mr="16px">
                <Button
                  onClick={handleCloseCheckinModal}
                  variant="outlined"
                  style={{
                    borderColor: '#d43737',
                    color: '#d43737',
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Button
                onClick={() => handleSaveCheckins(newCheckins)}
                variant="outlined"
                color="primary"
              >
                Save Checkins
              </Button>
            </Flex>
          </Box>
        </Modal>
      </Box>
      {isMobile && (
        <Box style={{ gridArea: 'combined-chart' }}>
          <Flex justifyContent="flex-end">
            <Flex alignItems="center" onClick={toggleChartDrawer}>
              <ChartControls> {activeChart}</ChartControls>
              <ArrowDrowdown />
            </Flex>
          </Flex>
          <Box>
            <LineChart
              title="This week"
              firstDataSet={formatCheckIn(activeChartData())}
              maxCheckins={3}
            />
          </Box>
        </Box>
      )}

      {!isMobile && (
        <>
          <Box style={{ gridArea: 'this-week' }}>
            <CheckinChartCard
              title="This week"
              maxCheckins={5}
              checkinTrendsData={formatCheckIn(statDataSet?.thisWeek)}
            />
          </Box>
          <Box style={{ gridArea: '6-week' }}>
            <TrendsChart
              title="6 week trends"
              dataSet={formatCheckIn(statDataSet?.sixWeek)}
              labelConfig={{
                label1: 'Last 6 Weeks',
              }}
            />
          </Box>
          <Box style={{ gridArea: '6-month' }}>
            <TrendsChart
              title="6 month trends"
              dataSet={formatCheckIn(statDataSet?.sixMonth)}
              labelConfig={{
                label1: 'Last 6 Months',
              }}
            />
          </Box>
        </>
      )}

      <MobileSelector
        title="Select Data Type"
        isDrawerOpen={isDataTypeDrawerOpen}
        toggleDrawer={toggleDataTypeDrawer}
      >
        <OptionLink
          label={'Check ins'}
          isSelected={statCardData === 'checkins'}
          onClick={() => setStatCardData('checkins')}
        />
        <OptionLink
          label={'Active Days'}
          isSelected={statCardData === 'active_days'}
          onClick={() => setStatCardData('active_days')}
        />
      </MobileSelector>

      <MobileSelector
        title="Select Date Range"
        isDrawerOpen={isChartDrawerOpen}
        toggleDrawer={toggleChartDrawer}
      >
        <OptionLink
          label={'This week'}
          isSelected={activeChart === 'This week'}
          onClick={() => setActiveChart('This week')}
        />
        <OptionLink
          label={'Six week'}
          isSelected={activeChart === 'Six week'}
          onClick={() => setActiveChart('Six week')}
        />
        <OptionLink
          label={'Six month'}
          isSelected={activeChart === 'Six month'}
          onClick={() => setActiveChart('Six month')}
        />
      </MobileSelector>
    </ProfileContainer>
  );
};

export default Profile;
