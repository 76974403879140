import { useQuery } from 'react-query';
import { getUserNotes } from '../mutations/notes';

export const useUserNotes = (uid) => {
  const { data: notes = [], isLoading, error } = useQuery(
    ['user-notes', uid],
    () => getUserNotes(uid),
    {
      enabled: !!uid,
      staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    }
  );

  return {
    notes,
    isLoading,
    error,
  };
};
